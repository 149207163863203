import { Api } from '../api'

export const GET_MEMBER_CHECK = "GET_MEMBER_CHECK";
export const GET_MEMBER_CHECK_PT = "GET_MEMBER_CHECK_PT";
export const GET_MEMBER_CHECK_CLASS = "GET_MEMBER_CHECK_CLASS";

export const GET_REVENUE_MARCOMM_MTD = "GET_REVENUE_MARCOMM_MTD";
export const GET_REVENUE_MARCOMM_COMPARE = 'GET_REVENUE_MARCOMM_COMPARE';

export const memberCheck = ({ orgId = "", clubId = "", firstMonthStart = "", firstMonthEnd = "", secondMonthStart = "", secondMonthEnd = "" }) => {
    return async (dispatch) => {
        try {
            let queryOrgClub = `orgId=${orgId}&clubId=${clubId}`;
            let queryDate = `firstMonthStart=${firstMonthStart}&firstMonthEnd=${firstMonthEnd}&secondMonthStart=${secondMonthStart}&secondMonthEnd=${secondMonthEnd}`;
            const resp = await Api.get(
                `dashboard/member-check?${queryOrgClub}&${queryDate}`
            )
            let data = resp.data.data ? resp.data.data : []
            dispatch({
                type: GET_MEMBER_CHECK,
                data,
            })
            // return data
        } catch (err) {
            console.log(err)
            throw err?.response?.data
        }
    }
}

export const memberCheckPt = ({ orgId = "", clubId = "", firstMonthStart = "", firstMonthEnd = "", secondMonthStart = "", secondMonthEnd = "" }) => {
    return async (dispatch) => {
        try {
            let queryOrgClub = `orgId=${orgId}&clubId=${clubId}`;
            let queryDate = `firstMonthStart=${firstMonthStart}&firstMonthEnd=${firstMonthEnd}&secondMonthStart=${secondMonthStart}&secondMonthEnd=${secondMonthEnd}`;
            const resp = await Api.get(
                `dashboard/member-check-pt?${queryOrgClub}&${queryDate}`
            )
            let data = resp.data.data ? resp.data.data : [];
            dispatch({
                type: GET_MEMBER_CHECK_PT,
                data,
            })
        } catch (err) {
            console.log(err)
            throw err?.response?.data
        }
    }
}

export const memberCheckClass = ({ orgId = "", clubId = "", firstMonthStart = "", firstMonthEnd = "", secondMonthStart = "", secondMonthEnd = "" }) => {
    return async (dispatch) => {
        try {
            let queryOrgClub = `orgId=${orgId}&clubId=${clubId}`;
            let queryDate = `firstMonthStart=${firstMonthStart}&firstMonthEnd=${firstMonthEnd}&secondMonthStart=${secondMonthStart}&secondMonthEnd=${secondMonthEnd}`;
            const resp = await Api.get(
                `dashboard/member-check-class?${queryOrgClub}&${queryDate}`
            )
            let data = resp.data.data ? resp.data.data : [];
            dispatch({
                type: GET_MEMBER_CHECK_CLASS,
                data,
            })
        } catch (err) {
            console.log(err)
            throw err?.response?.data
        }
    }
}

export const getRevenueMarcomm = ({ orgId = "", clubId = "", startDate = "", endDate = "", mtd = false, compareMtd = false }) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(
                `dashboard/revenue-marcomm?orgId=${orgId}&clubId=${clubId}&startDate=${startDate}&endDate=${endDate}`
            )
            let data = resp.data.data ? resp.data.data : []

            let dispatchType;

            if (mtd) dispatchType = GET_REVENUE_MARCOMM_MTD;
            else if (compareMtd) dispatchType = GET_REVENUE_MARCOMM_COMPARE;

            dispatch({
                type: dispatchType,
                data
            })
        } catch (error) {
            console.log(error);
            throw error?.response?.data;
        }
    }
}