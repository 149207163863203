import {
    GET_LIST_MEMBERSHIP,
} from "../actions/MembershipActions";

const initialState = {
  membershipList: [],
};

const MembershipReducer = function(state = initialState, action) {
  switch (action.type) {
    case GET_LIST_MEMBERSHIP: {
      return {
        ...state,
        membershipList: action.data
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default MembershipReducer;
