import { GET_LIST_CLUB, GET_CLUB_BUDGET } from "../actions/ClubActions";

const initialState = {
  clubList: [],
  clubBudget: [],
};

const ClubReducer = function(state = initialState, action) {
  switch (action.type) {
    case GET_LIST_CLUB: {
      return {
        ...state,
        clubList: action.data
      };
    }
    case GET_CLUB_BUDGET: {
      return {
        ...state,
        clubBudget: action.data
      }
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default ClubReducer;
