import { Api } from '../api'

export const GET_CLASSES = 'GET_CLASSES';
export const GET_CLASS_CATEGORY = 'GET_CLASS_CATEGORY';
export const GET_INSTRUCTOR = 'GET_INSTRUCTOR';
export const GET_PACKAGE_CLASS = 'GET_PACKAGE_CLASS';
export const GET_CLASS_MOVEMENT = 'GET_CLASS_MOVEMENT';
export const GET_MOVEMENT_DETAIL = 'GET_MOVEMENT_DETAIL';

export const getClasses = (page, size, orgId = '') => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`class/classes?page=${page}&size=${size}&orgId=${orgId}`)
            let data = resp.data.data ? resp.data.data : []
            
            dispatch({
                type: GET_CLASSES,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getClassCategory = () => {
    return async (dispatch) => {
        try {
            const resp = await Api.get('class/category')
            let data = resp.data.data ? resp.data.data : []
            
            dispatch({
                type: GET_CLASS_CATEGORY,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getInstructor = () => {
    return async (dispatch) => {
        try {
            const resp = await Api.get('class/instructor')
            let data = resp.data.data ? resp.data.data : []
            
            dispatch({
                type: GET_INSTRUCTOR,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getPackageClass = (page, size, orgId = '', clubId) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`package/classes?page=${page}&size=${size}&orgId=${orgId}&clubId=${clubId}`)
            let data = resp.data.data ? resp.data.data : []
            
            dispatch({
                type: GET_PACKAGE_CLASS,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getMovement = ({ page = "", size = "", orgId = "", classId = "", type = "" }) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`class/movement?page=${page}&size=${size}&orgId=${orgId}&classId=${classId}&type=${type}`)
            let data = resp.data.data ? resp.data.data : []

            dispatch({
                type: GET_CLASS_MOVEMENT,
                data,
            })
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getMovementDetail = (id) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`class/movement/${id}`)
            let data = resp.data.data ? resp.data.data : []

            dispatch({
                type: GET_MOVEMENT_DETAIL,
                data
            })

        } catch (err) {
            throw err?.response?.data
        }
    }
}

