import {
  SET_USER_DATA,
  REMOVE_USER_DATA,
  USER_LOGGED_OUT,
  GET_ROLE,
  GET_LIST_USER,
  GET_USER_DETAIL,

  SET_ADMINISTRATOR_ROLE,
  SET_ADMIN_ROLE,
  SET_SALES_ROLE,
  SET_INVESTOR_ROLE,
  SET_OPMAN_ROLE,

  UNRECOGNIZED_ROLE
} from "../actions/UserActions";

const initialState = {
  listRole: [],
  listUser: [],
  userDetail: [],

  roleUser: {
    isAdmin: false,
    isAdministrator: false,
    isSales: false,
    isInvestor: false,
    isOpMan: false,  // operational manager

    unrecognized_role: false,
  }
};

const userReducer = function (state = initialState, action) {
  switch (action.type) {
    case SET_USER_DATA: {
      return {
        ...state,
        ...action.data
      };
    }
    case REMOVE_USER_DATA: {
      return {
        ...state
      };
    }
    case USER_LOGGED_OUT: {
      return state;
    }
    case GET_ROLE: {
      return {
        ...state,
        listRole: action.data
      };
    }
    case GET_LIST_USER: {
      return {
        ...state,
        listUser: action.data
      };
    }
    case GET_USER_DETAIL: {
      return {
        ...state,
        userDetail: action.data
      }
    }
    case SET_ADMIN_ROLE: {
      return {
        ...state,
        roleUser: {
          ...state.roleUser,
          isAdmin: true
        }
      }
    }
    case SET_ADMINISTRATOR_ROLE: {
      return {
        ...state,
        roleUser: {
          ...state.roleUser,
          isAdministrator: true
        }
      }
    }
    case SET_SALES_ROLE: {
      return {
        ...state,
        roleUser: {
          ...state.roleUser,
          isSales: true
        }
      }
    }
    case SET_INVESTOR_ROLE: {
      return {
        ...state,
        roleUser: {
          ...state.roleUser,
          isInvestor: true
        }
      }
    }
    case SET_OPMAN_ROLE: {
      return {
        ...state,
        roleUser: {
          ...state.roleUser,
          isOpMan: true
        }
      }
    }
    case UNRECOGNIZED_ROLE: {
      return {
        ...state,
        roleUser: {
          ...state.roleUser,
          unrecognized_role: true
        }
      }
    }

    default: {
      return state;
    }
  }
};

export default userReducer;
