import { Api } from '../api'

export const GET_BOOKING_CLASS = "GET_BOOKING_CLASS";
export const GET_BOOKING_TRAINER = "GET_BOOKING_TRAINER";

export const getBookingClass = (orgId = '', clubId = '', memberId = '', instructorId = '', classId = '', page, size) => {
  return async (dispatch) => {
    try {
      // const resp = await Api.get(`schedule/booking-class?org=${org}&clubId=${clubId}&memberId=${memberId}&instructorId${instructorId}&classId=${classId}`)
      const resp = await Api.get(`schedule/booking-class?orgId=${orgId}&clubId=${clubId}`)
      let data = resp.data.data ? resp.data.data : []
      console.log('data', data)
      dispatch({
        type: GET_BOOKING_CLASS,
        data,
      })
      // return data
    } catch (err) {
      console.log(err)
      throw err?.response?.data
    }
  }
};

export const getBookingTrainer = (org = 12, clubId = '', memberId = '', instructorId = '', classId = '', page, size) => {
  return async (dispatch) => {
    try {
      const resp = await Api.get(`schedule/booking-trainer?org=${org}&clubId=${clubId}&memberId=${memberId}&instructorId${instructorId}`)
      let data = resp.data.data ? resp.data.data : []

      dispatch({
        type: GET_BOOKING_TRAINER,
        data,
      })
      // return data
    } catch (err) {
      console.log(err)
      throw err?.response?.data
    }
  }
};

