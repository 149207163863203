import {
  GET_LIST_LEADS,
  GET_ONLY_LEADS,
  GET_DETAIL_LEADS,
  GET_LIST_ACTIVITY,
  GET_AGE_LEAD,
  GET_GENDER_LEAD,
  GET_LEAD_FOLLOW_UP,
  GET_LEAD_MTD_BACKOFFICE,
  GET_LEAD_MTD_LINK,
  GET_LEAD_COMPARE_TODAY_BACKOFFICE,
  GET_LEAD_COMPARE_TODAY_LINK,
  GET_LEAD_COMPARE_MTD_BACKOFFICE,
  GET_LEAD_COMPARE_MTD_LINK,
  GET_LEAD_TODAY_BACKOFFICE,
  GET_LEAD_TODAY_LINK,
  GET_LEAD_SALES,
  GET_LEAD_SOURCE,
} from "../actions/LeadsActions"

const initialState = {
  leadsList: [],
  leadsOnly: [],
  detailLeads: [],
  listActivity: [],
  reportLead: {
    today: {
      backoffice: "",
      link: "",
    },
    mtd: {
      backoffice: "",
      link: "",
    },
    gender: {
      male: "",
      female: "",
    },
    age: []
  },
  compareLead: {
    today: {
      backoffice: "",
      link: "",
    },
    mtd: {
      backoffice: "",
      link: "",
    },
  },
  leadFollowUp: [],
  leadSource: [],
  leadSales: [],
};

const LeadsReducer = function(state = initialState, action) {
  switch (action.type) {
    case GET_LIST_LEADS: {
      return {
        ...state,
        leadsList: action.data
      };
    }
    case GET_ONLY_LEADS: {
      return {
        ...state,
        leadsOnly: action.data
      };
    }
    case GET_DETAIL_LEADS: {
      return {
        ...state,
        detailLeads: action.data
      };
    }
    case GET_LIST_ACTIVITY: {
      return {
        ...state,
        listActivity: action.data
      };
    }
    case GET_LEAD_TODAY_BACKOFFICE: {
      return {
        ...state,
        reportLead: {
          ...state.reportLead,
          today: {
            ...state.reportLead.today,
            backoffice: action.data
          }
        }
      }
    }

    case GET_LEAD_TODAY_LINK: {
      return {
        ...state,
        reportLead: {
          ...state.reportLead,
          today: {
            ...state.reportLead.today,
            link: action.data
          }
        }
      }
    }

    case GET_LEAD_MTD_BACKOFFICE: {
      return {
        ...state,
        reportLead: {
          ...state.reportLead,
          mtd: {
            ...state.reportLead.mtd,
            backoffice: action.data
          }
        }
      }
    }

    case GET_LEAD_MTD_LINK: {
      return {
        ...state,
        reportLead: {
          ...state.reportLead,
          mtd: {
            ...state.reportLead.mtd,
            link: action.data
          }
        }
      }
    }
    case GET_LEAD_COMPARE_TODAY_BACKOFFICE: {
      return {
        ...state,
        compareLead: {
          ...state.compareLead,
          today: {
            ...state.compareLead.today,
            backoffice: action.data
          }
        }
      }
    }
    case GET_LEAD_COMPARE_TODAY_LINK: {
      return {
        ...state,
        compareLead: {
          ...state.compareLead,
          today: {
            ...state.compareLead.today,
            link: action.data
          }
        }
      }
    }
    case GET_LEAD_COMPARE_MTD_BACKOFFICE: {
      return {
        ...state,
        compareLead: {
          ...state.compareLead,
          mtd: {
            ...state.compareLead.mtd,
            backoffice: action.data
          }
        }
      }
    }
    case GET_LEAD_COMPARE_MTD_LINK: {
      return {
        ...state,
        compareLead: {
          ...state.compareLead,
          mtd: {
            ...state.compareLead.mtd,
            link: action.data
          }
        }
      }
    }
    case GET_LEAD_FOLLOW_UP: {
      return {
        ...state,
        leadFollowUp: action.data
      }
    }

    case GET_LEAD_SOURCE: {
      return {
        ...state,
        leadSource: action.data
      }
    }

    case GET_LEAD_SALES: {
      return {
        ...state,
        leadSales: action.data
      }
    }

    case GET_GENDER_LEAD: {
      return {
        ...state,
        reportLead: {
          ...state.reportLead,
          gender: {
            male: action.data[0]?.male,
            female: action.data[0]?.female
          }
        }
      }
    }

    case GET_AGE_LEAD: {
      return {
        ...state,
        reportLead: {
          ...state.reportLead,
          age: action.data
        }
      }
    }

    default: {
      return {
        ...state,
      };
    }
  }
};

export default LeadsReducer;
