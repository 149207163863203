import { Api } from '../api'

export const GET_COMMISION_SALES = "GET_COMMISION_SALES";
export const GET_CLUB_HITS = "GET_CLUB_HITS";

export const commisionSales = ({ orgId = "", clubId = "", startMonth = "", endMonth = "" }) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(
                `commision/sales?orgId=${orgId}&clubId=${clubId}&startMonth=${startMonth}&endMonth=${endMonth}`
            )

            let data = resp.data.data ? resp.data.data : [];

            dispatch({
                type: GET_COMMISION_SALES,
                data,
            })
            // return data
        } catch (err) {
            console.log(err)
            throw err?.response?.data
        }
    }
}

export const getClubHits = ({ orgId = "", clubId = "", startMonth = "", endMonth = "" }) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(
                `commision/club-hits?orgId=${orgId}&clubId=${clubId}&startMonth=${startMonth}&endMonth=${endMonth}`
            )

            let data = resp.data.data ? resp.data.data : [];

            dispatch({
                type: GET_CLUB_HITS,
                data
            })

        } catch (err) {
            console.log(err)
            throw err?.response?.data
        }
    }
}
