import {
    GET_COMMISION_SALES,
	GET_CLUB_HITS
} from "../actions/CommisionActions";

const initialState = {
    salesCommision: [],
	clubHits: []
};

const CommisionReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_COMMISION_SALES: {
            return {
                ...state,
                salesCommision: action.data
            }
        }

		case GET_CLUB_HITS: {
			return {
				...state,
				clubHits: action.data
			}
		}

        default: {
            return {
                ...state,
            };
        }
    }
};

export default CommisionReducer;
