import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const appIndex = lazy(() => import("./AppIndex"));

const dailyReportRoutes = [
  {
    path: "/transaction/daily-report",
    component: appIndex,
    auth: authRoles.admin
  }
];

export default dailyReportRoutes;
