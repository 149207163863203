import { Api } from '../api'

export const GET_LIST_MDR = "GET_LIST_MDR";

export const getListMdr = (page, size) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(
                `master/mdr?page=${page}&size=${size}`
            )
            let data = resp.data.data ? resp.data.data : []

            dispatch({
                type: GET_LIST_MDR,
                data,
            })
            // return data
        } catch (err) {
            console.log(err)
            throw err?.response?.data
        }
    }
};