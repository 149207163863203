import { GET_MEMBERSHIP_MGM, GET_TRAINER_MGM } from "../actions/CoinActions";

const initialState = {
    membershipCoin: [],
    ptCoin: []
};

const CoinReducer = function(state = initialState, action) {
  switch (action.type) {
    case GET_MEMBERSHIP_MGM: {
      return {
        ...state,
        membershipCoin: action.data
      };
    }
    case GET_TRAINER_MGM: {
      return {
        ...state,
        ptCoin: action.data
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default CoinReducer;
