import { 
  GET_CLASS_SCHEDULE, 
  GET_CLASS_SCHEDULE_DETAIL, 
  GET_TRAINER_SCHEDULE, 
  GET_TRAINER_SCHEDULE_DETAIL,
  GET_MEMBER_SCHEDULE,
} from "../actions/ScheduleActions";

const initialState = {
  classSchedule: [],
  classScheduleDetail: [],
  trainerSchedule: [],
  trainerScheduleDetail: [],
  scheduleMember: [],
};

const ScheduleReducer = function(state = initialState, action) {
  switch (action.type) {
    case GET_CLASS_SCHEDULE: {
      return {
        ...state,
        classSchedule: action.data
      };
    }

    case GET_CLASS_SCHEDULE_DETAIL: {
      return {
        ...state,
        classScheduleDetail: action.data
      };
    }

    case GET_TRAINER_SCHEDULE: {
      return {
        ...state,
        trainerSchedule: action.data
      };
    }

    case GET_TRAINER_SCHEDULE_DETAIL: {
      return {
        ...state,
        trainerScheduleDetail: action.data
      };
    }

    case GET_MEMBER_SCHEDULE: {
      return {
        ...state,
        scheduleMember: action.data
      }
    }

    default: {
      return {
        ...state,
      };
    }
  }
};

export default ScheduleReducer;
