import {
    GET_LIST_SOURCES,
    GET_LIST_SUB_SOURCES,
} from "../actions/SourceActions";

const initialState = {
    sources: [],
    subSources: [],
};

const SourceReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_LIST_SOURCES: {
            return {
                ...state,
                sources: action.data,
            };
        }

        case GET_LIST_SUB_SOURCES: {
            return {
                ...state,
                subSources: action.data,
            };
        }

        default: {
            return {
                ...state,
            };
        }
    }
};

export default SourceReducer;
