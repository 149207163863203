import {
    GET_LIST_TRANSACTION,
    CLEAR_LIST_TRANSACTION,
    GET_DOWN_PAYMENT,

    GET_TRANSACTION_MEMBER,
    GET_BEST_REVENUE_SALES,

    GET_CONDUCT_PT_FIRST_MONTH,
    GET_CONDUCT_PT_SECOND_MONTH,
    GET_CONDUCT_FTHPA_FIRST_MONTH,
    GET_CONDUCT_FTHPA_SECOND_MONTH,

    GET_ORLOG_TRANSACTION,

    GET_HISTORY_TRAINER_PT,
    GET_HISTORY_TRAINER_FT_RFT_HPA,
    GET_RATE_AUTOPAY,

    GET_TRANSACTION_VOID,
    GET_REPORT_RETAINER,
    GET_DETAIL_RETAINER,

    GET_EMPLOYEE_GUEST_CHECKIN,
    GET_MEMBER_PT_CLIENT,
    GET_FC_REV_PERFORMANCE,
    GET_FC_UNIT_PERFORMANCE,
    GET_FC_PERFORMANCE_LIST,
    GET_LIST_TRANSFER_PACKAGE,
    GET_LIST_EXTEND_PT,

    CHECK_MEMBERSHIP_PROMO,

} from "../actions/TransactionActions";

const initialState = {
    listTransaction: [],
    listDownPayment: [],
    bestRevenueSales: [],
    memberTransaction: [],

    countConduct: {
        pt: { firstMonth: "", secondMonth: "" },
        fthpa: { firstMonth: "", secondMonth: "" },
    },

    orLogTransaction: [],
    historyTrainerSession: [],
    historyTrainerFtRftHpa: [],
    rateAutopay: [],

    transactionVoid: [],
    reportRetainer: [],
    detailRetainer: [],

    memberPtClient: [],
    employeeGuestCheckin: [],
    fcRevPerformance: [],
    fcUnitPerformance: [],
    fcPerformanceList: [],
    listTransfer: [],
    listExtendPt: [],

    checkMembershipPromo: [],
};

const TransactionReducer = function(state = initialState, action) {
    switch (action.type) {
        case GET_LIST_TRANSACTION: {
            return {
                ...state,
                listTransaction: action.data,
            };
        }

        case CLEAR_LIST_TRANSACTION: {
            return {
                ...state,
                listTransaction: []
            }
        }

        case GET_DOWN_PAYMENT: {
            return {
                ...state,
                listDownPayment: action.data
            }
        }

        case GET_TRANSACTION_MEMBER: {
            return {
                ...state,
                memberTransaction: action.data
            }
        }

        case GET_BEST_REVENUE_SALES: {
            return {
                ...state,
                bestRevenueSales: action.data
            }
        }

        case GET_CONDUCT_PT_FIRST_MONTH: {
            return {
                ...state,
                countConduct: {
                    ...state.countConduct,
                    pt: {
                        ...state.countConduct.pt,
                        firstMonth: action.data
                    }
                }
            }
        }

        case GET_CONDUCT_PT_SECOND_MONTH: {
            return {
                ...state,
                countConduct: {
                    ...state.countConduct,
                    pt: {
                        ...state.countConduct.pt,
                        secondMonth: action.data
                    }
                }
            }
        }

        case GET_CONDUCT_FTHPA_FIRST_MONTH: {
            return {
                ...state,
                countConduct: {
                    ...state.countConduct,
                    fthpa: {
                        ...state.countConduct.fthpa,
                        firstMonth: action.data
                    }
                }
            }
        }

        case GET_CONDUCT_FTHPA_SECOND_MONTH: {
            return {
                ...state,
                countConduct: {
                    ...state.countConduct,
                    fthpa: {
                        ...state.countConduct.fthpa,
                        secondMonth: action.data
                    }
                }
            }
        }

        case GET_ORLOG_TRANSACTION: {
            return {
                ...state,
                orLogTransaction: action.data
            }
        }

        case GET_HISTORY_TRAINER_PT: {
            return {
                ...state,
                historyTrainerSession: action.data
            }
        }

        case GET_HISTORY_TRAINER_FT_RFT_HPA: {
            return {
                ...state,
                historyTrainerFtRftHpa: action.data
            }
        }

        case GET_RATE_AUTOPAY: {
            return {
                ...state,
                rateAutopay: action.data
            }
        }

        case GET_TRANSACTION_VOID: {
            return {
                ...state,
                transactionVoid: action.data
            }
        }

        case GET_REPORT_RETAINER: {
            return {
                ...state,
                reportRetainer: action.data
            }
        }

        case GET_DETAIL_RETAINER: {
            return {
                ...state,
                detailRetainer: action.data
            }
        }

        case GET_EMPLOYEE_GUEST_CHECKIN: {
            return {
                ...state,
                employeeGuestCheckin: action.data
            }
        }

        case GET_MEMBER_PT_CLIENT: {
            return {
                ...state,
                memberPtClient: action.data
            }
        }

        case GET_FC_REV_PERFORMANCE: {
            return {
                ...state,
                fcRevPerformance: action.data
            }
        }

        case GET_FC_UNIT_PERFORMANCE: {
            return {
                ...state,
                fcUnitPerformance: action.data
            }
        }

        case GET_FC_PERFORMANCE_LIST: {
            return {
                ...state,
                fcPerformanceList: action.data
            }
        }

        case GET_LIST_TRANSFER_PACKAGE: {
            return {
                ...state,
                listTransfer: action.data
            }
        }

        case GET_LIST_EXTEND_PT: {
            return {
                ...state,
                listExtendPt: action.data
            }
        }

        case CHECK_MEMBERSHIP_PROMO: {
            return {
                ...state,
                checkMembershipPromo: action.data
            }
        }

        default: {
            return {
                ...state,
            };
        }
    }
};

export default TransactionReducer;
