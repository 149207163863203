import History from '@history';
import localStorageService from 'app/services/localStorageService';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
const baseURL = process.env.REACT_APP_BASE_URL_LOCAL

const accessToken = localStorage.getItem('jwt_token')

export const Api = axios.create({
    baseURL,
    headers: {
        Accept: 'application/json',
        // 'Access-Control-Allow-Origin': 'Authorization',
        'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: `Bearer ${localStorage.getItem('jwt_token')}`,
    },
    mode: 'cors',
    // credentials: true,
    // crossdomain: true,
})

Api.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response.status === 401) {
            localStorageService.clearItem()
            History.push('/session/signin')
            NotificationManager.error('Please Login First', "You are not Authorized")
        }
        // return error;
    }
);
