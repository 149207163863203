import { GET_LIST_EMPLOYEE, GET_EMPLOYEE_CHECKIN, GET_SALES_BUDGET } from "../actions/EmployeeAction";

const initialState = {
  employeeList: [],
  employeeCheckin: [],
  salesBudget: [],
};

const EmployeeReducer = function(state = initialState, action) {
  switch (action.type) {
    case GET_LIST_EMPLOYEE: {
      return {
        ...state,
        employeeList: action.data
      };
    }

    case GET_EMPLOYEE_CHECKIN: {
      return {
        ...state,
        employeeCheckin: action.data
      }
    }

    case GET_SALES_BUDGET: {
      return {
        ...state,
        salesBudget: action.data
      }
    }

    default: {
      return {
        ...state,
      };
    }
  }
};

export default EmployeeReducer;
