import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const appIndex = lazy(() => import("./AppIndex"));

const checkinManualRoutes = [
  {
    path: "/checkin-manual",
    component: appIndex,
    auth: authRoles.admin
  }
];

export default checkinManualRoutes;
