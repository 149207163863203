import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { classList } from "@utils";
import DropDownMenuItem from "./DropDownMenuItem";

class DropDownMenu extends Component {
  state = {
    open: false,
  };

  onItemClick = (e) => {
    e.preventDefault();
    this.setState({ open: !this.state.open });
  };

  hasAccess = (val) => {
    return this.props.menuAccess.find(el => el === val)
  }

  renderLevels = (items) =>
    items.map((item, i) => {
      if (item.children && this.hasAccess(item.value)) {
        return (
          <DropDownMenuItem key={i} item={item}>
            {this.renderLevels(item.children)}
          </DropDownMenuItem>
        );
      } else {
        return (
          <li
            key={i}
            className={classList({
              "nav-item": true,
              open: this.state.open,
            })}
            onClick={this.props.closeSecSidenav}
          >
            {
              item.hasAccess && this.hasAccess(item.value) &&
              <NavLink activeClassName="selected" exact to={item.path}>
                <i className={`nav-icon ${item.icon}`}></i>
                <span className="item-name">{item.label}</span>
              </NavLink>
            }
          </li>
        );
      }
    });

  render() {
    return <ul className="childNav">{this.renderLevels(this.props.menu)}</ul>;
  }
}

export default DropDownMenu;
