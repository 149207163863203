import { combineReducers } from "redux";
import LoginReducer from "./LoginReducer";
import UserReducer from "./UserReducer";
import LayoutReducer from "./LayoutReducer";
import ScrumBoardReducer from "./ScrumBoardReducer";
import NotificationReducer from "./NotificationReducer";
import EcommerceReducer from "./EcommerceReducer";
import BrandReducer from "./BrandReducer";
import SelectReducer from "./SelectReducer";
import ClubReducer from "./ClubReducer";
import ClassReducer from "./ClassReducer";
import TrainerReducer from "./TrainerReducer";
import AdditionalReducer from "./AdditioinalReducer";
import MembershipReducer from "./MembershipReducer";
import ScheduleReducer from "./ScheduleReducer";
import TransactionReducer from "./TransactionReducer";
import BookingReducer from "./BookingReducer";
import MemberReducer from "./MemberReducer";
import LeadsReducer from "./LeadsReducer";
import ImagesliderReducer from "./ImagesliderReducer";
import EmployeeReducer from "./EmployeeReducer";
import KpiReducer from "./KpiReducer";
import ComplementaryReducer from "./ComplementaryReducer"
import MdrReducer from "./MdrReducer";
import DashboardReducer from "./DashboardReducer";
import CommisionReducer from "./CommisionReducer";
import CoinReducer from "./CoinReducer";
import GuestFormReducer from "./GuestFormReducer";
import SourceReducer from "./SourceReducer";

const RootReducer = combineReducers({
  login: LoginReducer,
  user: UserReducer,
  layout: LayoutReducer,
  scrumboard: ScrumBoardReducer,
  notification: NotificationReducer,
  ecommerce: EcommerceReducer,
  brands: BrandReducer,
  selectList: SelectReducer,
  clubs: ClubReducer,
  kpi: KpiReducer,
  classes: ClassReducer,
  trainers: TrainerReducer,
  memberships: MembershipReducer,
  schedules: ScheduleReducer,
  transactions: TransactionReducer,
  bookings: BookingReducer,
  members: MemberReducer,
  leads: LeadsReducer,
  sliders: ImagesliderReducer,
  employee: EmployeeReducer,
  complementary: ComplementaryReducer,
  mdr: MdrReducer,
  dashboard: DashboardReducer,
  commisions: CommisionReducer,
  coins: CoinReducer,
  guestForm: GuestFormReducer,
  source: SourceReducer,
});

export default RootReducer;
