import { GET_LIST_KPI } from "../actions/KpiActions";

const initialState = {
  listKpi: [],
};

const KpiReducer = function(state = initialState, action) {
  switch (action.type) {
    case GET_LIST_KPI: {
      return {
        ...state,
        listKpi: action.data
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default KpiReducer;
