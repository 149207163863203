import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const appIndex = lazy(() => import("./AppIndex"));

const reportEmployeeRoutes = [
  {
    path: "/transaction/employee",
    component: appIndex,
    auth: authRoles.admin
  }
];

export default reportEmployeeRoutes;
