import { Api } from '../api'

export const GET_MEMBERSHIP_MGM = "GET_MEMBERSHIP_MGM";
export const GET_TRAINER_MGM = 'GET_TRAINER_MGM';

export const getMembershipMgm = ({ clubId = "", memberPackage = "", voucherPackage = "" }) => {
	return async (dispatch) => {
		try {
			const resp = await Api.get(`coin/mgm-coin?clubId=${clubId}&memberPackage=${memberPackage}&voucherPackage=${voucherPackage}`)
			let data = resp.data.data ? resp.data.data : []

			dispatch({
				type: GET_MEMBERSHIP_MGM,
				data,
			})
			// return data
		} catch (err) {
			console.log(err)
			throw err?.response?.data
		}
	}
};

export const getTrainerMgm = ({ orgId = "", clubId = "", packageTrainerId = "" }) => {
	return async (dispatch) => {
		try {
			const resp = await Api.get(`coin/pt-coin?orgId=${orgId}&clubId=${clubId}&packageTrainerId=${packageTrainerId}`);
			let data = resp.data.data ? resp.data.data : [];

			dispatch({
				type: GET_TRAINER_MGM,
				data
			})
		} catch (error) {
			console.log(error);
			throw error?.response?.data;
		}
	}
}