import { Api } from '../api'

export const GET_COUNTRY_LIST = 'GET_COUNTRY_LIST';
export const GET_PROVINCE_LIST = 'GET_PROVINCE_LIST';
export const GET_CITY_LIST = 'GET_CITY_LIST';
export const GET_SELECT_APPOINTMENT_STATUS = 'GET_APPOINTMENT_STATUS';
export const GET_SELECT_APPOINTMENT_SHOW_STATUS = 'GET_APPOINTMENT_SHOW_STATUS';
export const GET_SELECT_ORG = 'GET_SELECT_ORG';
export const GET_SELECT_CLASS_CATEGORY = 'GET_SELECT_CLASS_CATEGORY';
export const GET_SELECT_CLASS_TYPE = 'GET_SELECT_CLASS_TYPE';
export const GET_SELECT_LEVEL = 'GET_SELECT_LEVEL';
export const GET_SELECT_MEMBERSHIP_TYPE = 'GET_SELECT_MEMBERSHIP_TYPE';
export const GET_SELECT_MEMBERSHIP_CATEGORY = 'GET_SELECT_MEMBERSHIP_CATEGORY';
export const GET_SELECT_CLUB = 'GET_SELECT_CLUB';
export const GET_SELECT_CLASS_BY_ORG = 'GET_SELECT_CLASS_BY_ORG';
export const GET_SELECT_INSTRUCTOR = 'GET_SELECT_INSTRUCTOR';
export const GET_SELECT_TRAINER = 'GET_SELECT_TRAINER';
export const GET_SELECT_TRAINER_SESSION = "GET_SELECT_TRAINER_SESSION";
export const GET_SELECT_PROMO = 'GET_SELECT_PROMO';
export const GET_SELECT_SHIFT = 'GET_SELECT_SHIFT';
export const GET_SELECT_MEMBERSHIP_PAYMENT = 'GET_SELECT_MEMBERSHIP_PAYMENT';
export const GET_SELECT_SOURCE = 'GET_SELECT_SOURCE';
export const GET_SELECT_LEAD_SOURCE = 'GET_SELECT_LEAD_SOURCE';
export const GET_SELECT_EMPLOYEE = 'GET_SELECT_EMPLOYEE';
export const GET_SELECT_FOLLOW_UP_TYPE = 'GET_SELECT_FOLLOW_UP_TYPE';
export const GET_SELECT_FOLLOW_UP_STATUS = 'GET_SELECT_FOLLOW_UP_STATUS';
export const GET_SELECT_DEPARTMENT = 'GET_SELECT_DEPARTMENT';
export const GET_SELECT_POSITION = 'GET_SELECT_POSITION';
export const GET_SELECT_RELIGION = 'GET_SELECT_RELIGION';
export const GET_SELECT_ROLE = 'GET_SELECT_ROLE';
export const GET_SELECT_KPI = 'GET_SELECT_KPI';
export const GET_SELECT_MDR = 'GET_SELECT_MDR';
export const GET_SELECT_DISC_MEMBERSHIP = "GET_SELECT_DISC_MEMBERSHIP";
export const GET_SELECT_DISC_TRAINER = "GET_SELECT_DISC_TRAINER";
export const GET_SELECT_MST_SOURCE = "GET_SELECT_MST_SOURCE";
export const GET_SELECT_MST_SUB_SOURCE = "GET_SELECT_MST_SUB_SOURCE";
export const GET_SELECT_MEMBERSHIP_BONUS = "GET_SELECT_MEMBERSHIP_BONUS";
export const GET_SELECT_TRANSACTION_TAG = "GET_SELECT_TRANSACTION_TAG";

export const REMOVE_CLUB_LIST = 'REMOVE_CLUB_LIST';
export const REMOVE_EMPLOYEE_LIST = 'REMOVE_EMPLOYEE_LIST';

export const getCountryList = () => {
    return async (dispatch) => {
        try {
            const resp = await Api.get('select/country')
            let data = resp.data.data ? resp.data.data : []

            dispatch({
                type: GET_COUNTRY_LIST,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getProvinceByCountry = (countryId) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`select/province?countryId=${countryId}`)
            let data = resp.data.data ? resp.data.data : []

            dispatch({
                type: GET_PROVINCE_LIST,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getCityByProvince = (provinceId) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`select/city?provinceId=${provinceId}`)
            let data = resp.data.data ? resp.data.data : []

            dispatch({
                type: GET_CITY_LIST,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getOrgList = () => {
    return async (dispatch) => {
        try {
            const resp = await Api.get('select/org')
            let data = resp.data.data ? resp.data.data : []

            dispatch({
                type: GET_SELECT_ORG,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getSelectAppointmentStatus = () => {
    return async (dispatch) => {
        try {
            const resp = await Api.get('leads/follow-up-appointment')
            let data = resp.data.data ? resp.data.data : []

            dispatch({
                type: GET_SELECT_APPOINTMENT_STATUS,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getSelectAppointmentShowStatus = (appointmentId) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`leads/follow-up-appointment-status?appointmentId=${appointmentId}&size=2`)
            let data = resp.data.data ? resp.data.data : []

            dispatch({
                type: GET_SELECT_APPOINTMENT_SHOW_STATUS,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getSelectCategory = () => {
    return async (dispatch) => {
        try {
            const resp = await Api.get('select/classCategory')
            let data = resp.data.data ? resp.data.data : []

            dispatch({
                type: GET_SELECT_CLASS_CATEGORY,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getSelectClassType = () => {
    return async (dispatch) => {
        try {
            const resp = await Api.get('select/classType')
            let data = resp.data.data ? resp.data.data : []

            dispatch({
                type: GET_SELECT_CLASS_TYPE,
                data
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getSelectLevel = () => {
    return async (dispatch) => {
        try {
            const resp = await Api.get('select/trainerLevel')
            let data = resp.data.data ? resp.data.data : []

            dispatch({
                type: GET_SELECT_LEVEL,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getSelectMembershipType = () => {
    return async (dispatch) => {
        try {
            const resp = await Api.get('select/membershipType')
            let data = resp.data.data ? resp.data.data : []

            dispatch({
                type: GET_SELECT_MEMBERSHIP_TYPE,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getSelectMembershipCategory = () => {
    return async (dispatch) => {
        try {
            const resp = await Api.get('select/membershipCategory')
            let data = resp.data.data ? resp.data.data : []

            dispatch({
                type: GET_SELECT_MEMBERSHIP_CATEGORY,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getSelectClub = (orgId = "") => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`select/club?orgId=${orgId}`)
            let data = resp.data.data ? resp.data.data : []

            dispatch({
                type: GET_SELECT_CLUB,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getSelectClassbyOrg = (orgId) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`select/classByOrg?orgId=${orgId}`)
            let data = resp.data.data ? resp.data.data : []

            dispatch({
                type: GET_SELECT_CLASS_BY_ORG,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getSelectInstructor = (orgId) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`select/instructor?orgId=${orgId}`)
            let data = resp.data.data ? resp.data.data : []

            dispatch({
                type: GET_SELECT_INSTRUCTOR,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getSelectTrainer = (orgId, clubId) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`select/trainers?orgId=${orgId}&clubId=${clubId}`)
            let data = resp.data.data ? resp.data.data : []

            dispatch({
                type: GET_SELECT_TRAINER,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getSelectPromo = (orgId) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`select/membershipPromo?orgId=${orgId}`)
            let data = resp.data.data ? resp.data.data : []

            dispatch({
                type: GET_SELECT_PROMO,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getSelectShift = (orgId) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`select/shift?orgId=${orgId}`)
            let data = resp.data.data ? resp.data.data : []

            dispatch({
                type: GET_SELECT_SHIFT,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getSelectPaymentType = () => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`select/membershipPayment`)
            let data = resp.data.data ? resp.data.data : []

            dispatch({
                type: GET_SELECT_MEMBERSHIP_PAYMENT,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

// distinct source dari tabel lead
export const getSelectSource = () => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`select/leadSource`)
            let data = resp.data.data ? resp.data.data : []

            dispatch({
                type: GET_SELECT_SOURCE,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

// dari tabel ua_mst_leads_sources
export const getSelectLeadSource = () => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`select/mstLeadSource`)
            let data = resp.data.data ? resp.data.data : []

            dispatch({
                type: GET_SELECT_LEAD_SOURCE,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getSelectEmployee = ({ orgId = '', clubId = '', positionId = '', departmentId = '', isActive = 1 }) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`select/employee?orgId=${orgId}&clubId=${clubId}&positionId=${positionId}&departmentId=${departmentId}&isActive=${isActive}`)
            let data = resp.data.data && resp.data.data ? resp.data.data : [];

            dispatch({
                type: GET_SELECT_EMPLOYEE,
                data,
            })
            // return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getSelectFollowupType = () => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`leads/follow-up-type`)
            let data = resp.data.data ? resp.data.data.list : []
            dispatch({
                type: GET_SELECT_FOLLOW_UP_TYPE,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getSelectFollowupStatus = (typeId) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`leads/follow-up-type-status?followUpTypeId=${typeId}`)
            let data = resp.data.data ? resp.data.data.list : []

            dispatch({
                type: GET_SELECT_FOLLOW_UP_STATUS,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getSelectDepartment = () => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`select/department`)
            let data = resp.data.data ? resp.data.data : []

            dispatch({
                type: GET_SELECT_DEPARTMENT,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getSelectPosition = () => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`select/position`)
            let data = resp.data.data ? resp.data.data : []

            dispatch({
                type: GET_SELECT_POSITION,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getSelectReligion = () => {
    return async (dispatch) => {
        try {
            const resp = await Api.get('select/religion')
            let data = resp.data.data ? resp.data.data : []

            dispatch({
                type: GET_SELECT_RELIGION,
                data
            })
            return data;
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getSelectRole = () => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`select/role`)
            let data = resp.data.data ? resp.data.data : []

            dispatch({
                type: GET_SELECT_ROLE,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const getSelectKpi = () => {
    return async (dispatch) => {
        try {
            const resp = await Api.get('select/kpi')
            let data = resp.data.data ? resp.data.data : []

            dispatch({
                type: GET_SELECT_KPI,
                data,
            })
            return data
        } catch (err) {
            throw err?.response?.data
        }
    }
}

export const emptyClubList = () => {
    return async (dispatch) => {
        dispatch({
            type: REMOVE_CLUB_LIST,
            data: []
        })
    }
}

export const emptyEmployeeList = () => {
    return async (dispatch) => {
        dispatch({
            type: REMOVE_EMPLOYEE_LIST,
            data: []
        })
    }
}

export const getSelectSessionTrainer = () => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`select/trainer-session`)
            let data = resp.data.data ? resp.data.data : []

            dispatch({
                type: GET_SELECT_TRAINER_SESSION,
                data
            })
            return data;
        } catch (err) {

        }
    }
}

export const getSelectMdr = () => {
    return async (dispatch) => {
        try {
            const resp = await Api.get('select/mdr');
            let data = resp.data.data ? resp.data.data : []

            dispatch({
                type: GET_SELECT_MDR,
                data
            })
            return data;
        } catch (error) {

        }
    }
}

export const getDiscMembership = ({ orgId = "", clubId = "" }) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`select/discMembership?org_id=${orgId}&clubId=${clubId}`);
            let data = resp.data.data ? resp.data.data : []

            dispatch({
                type: GET_SELECT_DISC_MEMBERSHIP,
                data
            })
            return data;
        } catch (error) {

        }
    }
}

export const getDiscTrainer = ({ orgId = "", clubId = "", ptLevel = "" }) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`select/discTrainer?orgId=${orgId}&clubId=${clubId}&ptLevel=${ptLevel}`);
            let data = resp.data.data ? resp.data.data : []

            dispatch({
                type: GET_SELECT_DISC_TRAINER,
                data
            })
            return data;
        } catch (error) {

        }
    }
}

export const getSource = ({ isActive = 1 }) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`select/source?isActive=${isActive}`);
            let data = resp.data.data ? resp.data.data : []

            dispatch({
                type: GET_SELECT_MST_SOURCE,
                data
            })
            return data;
        } catch (error) {

        }
    }
}

export const getSubSource = ({ sourceId = "", isActive = 1 }) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`select/sub-source?sourceId=${sourceId}&isActive=${isActive}`);
            let data = resp.data.data ? resp.data.data : []

            dispatch({
                type: GET_SELECT_MST_SUB_SOURCE,
                data
            })
            return data;
        } catch (error) {

        }
    }
}

export const getSelectMembershipBonus = () => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`select/membershipBonus`);
            let data = resp.data.data ? resp.data.data : []

            dispatch({
                type: GET_SELECT_MEMBERSHIP_BONUS,
                data
            })
            return data;
        } catch (error) {
            
        }
    }
}

export const getSelectTransactionTag = () => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`select/transaction-tag`);
            let data = resp.data.data ? resp.data.data : [];

            dispatch({
                type: GET_SELECT_TRANSACTION_TAG,
                data
            })
        } catch (error) {
            console.log(error);            
        }
    }
}
