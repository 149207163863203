import { Api } from '../api'

export const GET_LIST_COMPLEMENTARY = "GET_LIST_COMPLEMENTARY";
export const GET_DETAIL_COMPLEMENTARY = "GET_DETAIL_COMPLEMENTARY";

export const getComplementary = ({ page = '', size = '', orgId = '', clubId = '', type = '', startDate = '', endDate = '', search = '', isExport = false }) => {
  return async (dispatch) => {
    try {
      if (search === null) search = '';
      const resp = await Api.get(
        `complementary?page=${page}&size=${size}&orgId=${orgId}&clubId=${clubId}&type=${type}&startDate=${startDate}&endDate=${endDate}&search=${search}`
      )
      let data = resp.data.data ? resp.data.data : []

      if (isExport) {
          return data
      }

      dispatch({
        type: GET_LIST_COMPLEMENTARY,
        data,
      })
      // return data
    } catch (err) {
      console.log(err)
      throw err?.response?.data
    }
  }
};

export const getDetailComplementary = (id) => {
  return async (dispatch) => {
    try {
      const resp = await Api.get(`complementary/${id}`);
      let data = resp.data.data ? resp.data.data : [];

      dispatch({
        type: GET_DETAIL_COMPLEMENTARY,
        data,
      });
      // return data
    } catch (err) {
      console.log(err);
      throw err?.response?.data;
    }
  };
};
