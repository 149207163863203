import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const appIndex = lazy(() => import("./AppIndex"));

const budgetRoutes = [
  {
    path: "/master/club-budget",
    component: appIndex,
    auth: authRoles.admin
  }
];

export default budgetRoutes;
