import { Api } from '../api'

export const GET_LIST_CLUB = "GET_LIST_CLUB";
export const GET_CLUB_BUDGET = "GET_CLUB_BUDGET";

export const getListClub = () => {
  return async (dispatch) => {
    try {
      const resp = await Api.get('club')
      let data = resp.data.data ? resp.data.data : []

      dispatch({
        type: GET_LIST_CLUB,
        data,
      })
      // return data
    } catch (err) {
      console.log(err)
      throw err?.response?.data
    }
  }
};

export const getClubBudget = ({ page = '', size = '', orgId = '', clubId = '', kpiId = '', month = '', year = '' }) => {
  return async (dispatch) => {
    try {
      const resp = await Api.get(
        `club-budget?page=${page}&size=${size}&orgId=${orgId}&clubId=${clubId}&kpiId=${kpiId}&month=${month}&year=${year}`
      )
      let data = resp.data.data ? resp.data.data : []

      dispatch({
        type: GET_CLUB_BUDGET,
        data,
      })
      // return data
    } catch (err) {
      console.log(err)
      throw err?.response?.data
    }
  }
};

