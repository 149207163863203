import { Api } from '../api'

export const GET_LIST_LEVEL = "GET_LIST_LEVEL";
export const GET_PERSONAL_TRAINER = "GET_PERSONAL_TRAINER";

export const getListLevel = () => {
  return async (dispatch) => {
    try {
      const resp = await Api.get('trainer/level')
      let data = resp.data.data ? resp.data.data : []

      dispatch({
        type: GET_LIST_LEVEL,
        data,
      })
      // return data
    } catch (err) {
      console.log(err)
      throw err?.response?.data
    }
  }
};

export const getPersonalTrainer = ({ page = "", size = "", orgId = "", clubId = "", trainerId = "", search = "", session = "", expired = "", levelId = "", isActive = 1, price = "" }) => {
  return async (dispatch) => {
    try {
      if (search === null) search = "";
      const resp = await Api.get(`package/trainer?page=${page}&size=${size}&orgId=${orgId}&clubId=${clubId}&trainerId=${trainerId}&search=${search}&session=${session}&expired=${expired}&levelId=${levelId}&price=${price}&isActive=${isActive}`)
      let data = resp.data.data ? resp.data.data : []

      dispatch({
        type: GET_PERSONAL_TRAINER,
        data,
      })
      // return data
    } catch (err) {
      console.log(err)
      throw err?.response?.data
    }
  }
};
