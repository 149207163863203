import {
    GET_GUEST_FORM,
    CLEAR_GUEST_FORM,
    GET_GUEST_FORM_ACCESS,
    CLEAR_ACCESS,
    GET_LIST_ACCESS
} from "../actions/GuestFormActions"

const initialState = {
    guestForm: [],
    guestAccess: [],
    listAccess: []
}

const GuestFormReducer = function(state = initialState, action) {
    switch (action.type) {
        case GET_GUEST_FORM:
            return {
                ...state,
                guestForm: action.data
            };

        case CLEAR_GUEST_FORM:
            return {
                ...state,
                guestForm: []
            }

        case GET_GUEST_FORM_ACCESS:
            return {
                ...state,
                guestAccess: action.data
            }

        case CLEAR_ACCESS: 
            return {
                ...state,
                guestAccess: []
            }
        
        case GET_LIST_ACCESS: 
            return {
                ...state,
                listAccess: action.data
            }

        default:
            return {
                ...state
            }
    }
}

export default GuestFormReducer;