import { GET_LIST_ORG } from "../actions/BrandActions";

const initialState = {
  orgList: [],
};

const BrandReducer = function(state = initialState, action) {
  switch (action.type) {
    case GET_LIST_ORG: {
      return {
        ...state,
        orgList: action.data
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default BrandReducer;
