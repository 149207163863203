import { GET_LIST_ADD_ON } from "../actions/AdditionalActions";

const initialState = {
  addonList: [],
};

const AdditionalReducer = function(state = initialState, action) {
  switch (action.type) {
    case GET_LIST_ADD_ON: {
      return {
        ...state,
        addonList: action.data
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default AdditionalReducer;
