import {
    GET_CITY_LIST,
    GET_COUNTRY_LIST,
    GET_PROVINCE_LIST,
    GET_SELECT_CLASS_CATEGORY,
    GET_SELECT_CLASS_TYPE,
    GET_SELECT_APPOINTMENT_STATUS,
    GET_SELECT_APPOINTMENT_SHOW_STATUS,
    GET_SELECT_LEVEL,
    GET_SELECT_MEMBERSHIP_TYPE,
    GET_SELECT_MEMBERSHIP_CATEGORY,
    GET_SELECT_ORG,
    GET_SELECT_CLUB,
    GET_SELECT_CLASS_BY_ORG,
    GET_SELECT_INSTRUCTOR,
    GET_SELECT_TRAINER,
    GET_SELECT_TRAINER_SESSION,
    GET_SELECT_PROMO,
    GET_SELECT_SHIFT,
    GET_SELECT_MEMBERSHIP_PAYMENT,
    GET_SELECT_SOURCE,
    GET_SELECT_LEAD_SOURCE,
    GET_SELECT_EMPLOYEE,
    GET_SELECT_FOLLOW_UP_TYPE,
    GET_SELECT_FOLLOW_UP_STATUS,
    GET_SELECT_DEPARTMENT,
    GET_SELECT_POSITION,
    GET_SELECT_RELIGION,
    GET_SELECT_ROLE,
    GET_SELECT_KPI,
    GET_SELECT_MDR,
    GET_SELECT_DISC_MEMBERSHIP,
    GET_SELECT_DISC_TRAINER,
    GET_SELECT_MST_SOURCE,
    GET_SELECT_MST_SUB_SOURCE,
    GET_SELECT_MEMBERSHIP_BONUS,
    GET_SELECT_TRANSACTION_TAG,

    REMOVE_CLUB_LIST,
    REMOVE_EMPLOYEE_LIST,
} from "../actions/SelectAction";

const initialState = {
    countryList: [],
    provinceList: [],
    cityList: [],
    orgList: [],
    classCategory: [],
    classType: [],
    selectLevel: [],
    membershipType: [],
    membershipCategory: [],
    selectAppointmentStatus: [],
    selectAppointmentShowStatus: [],
    selectClub: [],
    selectClassbyOrg: [],
    selectInstructor: [],
    selectTrainer: [],
    selectTrainerSession: [],
    selectPromo: [],
    selectShift: [],
    selectPaymentType: [],
    selectSource: [],
    selectLeadSource: [],
    selectEmployee: [],
    selectFollowupType: [],
    selectFollowupStatus: [],
    selectDepartment: [],
    selectPosition: [],
    selectReligion: [],
    selectRole: [],
    selectKpi: [],
    selectMdr: [],
    discMembership: [],
    discTrainer: [],
    selectMstSource: [],
    selectMstSubSource: [],
    selectMembershipBonus: [],
    selectTransactionTag: [],
};

const SelectReducer = function(state = initialState, action) {
    switch (action.type) {
        case GET_COUNTRY_LIST: {
            return {
                ...state,
                countryList: action.data,
            };
        }

        case GET_PROVINCE_LIST: {
            return {
                ...state,
                provinceList: action.data,
            };
        }

        case GET_CITY_LIST: {
            return {
                ...state,
                cityList: action.data,
            };
        }

        case GET_SELECT_ORG: {
            return {
                ...state,
                orgList: action.data,
            };
        }

        case GET_SELECT_CLASS_CATEGORY: {
            return {
                ...state,
                classCategory: action.data,
            };
        }

        case GET_SELECT_CLASS_TYPE: {
            return {
                ...state,
                classType: action.data
            }
        }

        case GET_SELECT_LEVEL: {
            return {
                ...state,
                selectLevel: action.data,
            };
        }

        case GET_SELECT_MEMBERSHIP_TYPE: {
            return {
                ...state,
                membershipType: action.data,
            };
        }

        case GET_SELECT_MEMBERSHIP_CATEGORY: {
            return {
                ...state,
                membershipCategory: action.data,
            };
        }

        case GET_SELECT_APPOINTMENT_STATUS: {
            return {
                ...state,
                selectAppointmentStatus: action.data
            }
        }

        case GET_SELECT_APPOINTMENT_SHOW_STATUS: {
            return {
                ...state,
                selectAppointmentShowStatus: action.data
            }
        }

        case GET_SELECT_CLUB: {
            return {
                ...state,
                selectClub: action.data,
            };
        }

        case GET_SELECT_CLASS_BY_ORG: {
            return {
                ...state,
                selectClassbyOrg: action.data,
            };
        }

        case GET_SELECT_INSTRUCTOR: {
            return {
                ...state,
                selectInstructor: action.data,
            };
        }

        case GET_SELECT_TRAINER: {
            return {
                ...state,
                selectTrainer: action.data,
            };
        }

        case GET_SELECT_TRAINER_SESSION: {
            return {
                ...state,
                selectTrainerSession: action.data,
            };
        }

        case GET_SELECT_PROMO: {
            return {
                ...state,
                selectPromo: action.data,
            };
        }

        case GET_SELECT_SHIFT: {
            return {
                ...state,
                selectShift: action.data,
            };
        }

        case GET_SELECT_MEMBERSHIP_PAYMENT: {
            return {
                ...state,
                selectPaymentType: action.data,
            };
        }

        case GET_SELECT_SOURCE: {
            return {
                ...state,
                selectSource: action.data,
            };
        }

        case GET_SELECT_LEAD_SOURCE: {
            return {
                ...state,
                selectLeadSource: action.data,
            };
        }

        case GET_SELECT_EMPLOYEE: {
            return {
                ...state,
                selectEmployee: action.data,
            };
        }

        case GET_SELECT_FOLLOW_UP_TYPE: {
            return {
                ...state,
                selectFollowupType: action.data,
            };
        }

        case GET_SELECT_FOLLOW_UP_STATUS: {
            return {
                ...state,
                selectFollowupStatus: action.data,
            };
        }

        case GET_SELECT_DEPARTMENT: {
            return {
                ...state,
                selectDepartment: action.data,
            };
        }

        case GET_SELECT_POSITION: {
            return {
                ...state,
                selectPosition: action.data,
            };
        }

        case GET_SELECT_RELIGION: {
            return {
                ...state,
                selectReligion: action.data,
            }
        }

        case GET_SELECT_ROLE: {
            return {
                ...state,
                selectRole: action.data,
            };
        }

        case GET_SELECT_KPI: {
            return {
                ...state,
                selectKpi: action.data
            }
        }

        case GET_SELECT_MDR: {
            return {
                ...state,
                selectMdr: action.data
            }
        }

        case GET_SELECT_DISC_MEMBERSHIP: {
            return {
                ...state,
                discMembership: action.data
            }
        }

        case GET_SELECT_DISC_TRAINER: {
            return {
                ...state,
                discTrainer: action.data
            }
        }

        case GET_SELECT_MST_SOURCE: {
            return {
                ...state,
                selectMstSource: action.data
            }
        }

        case GET_SELECT_MST_SUB_SOURCE: {
            return {
                ...state,
                selectMstSubSource: action.data
            }
        }

        case GET_SELECT_MEMBERSHIP_BONUS: {
            return {
                ...state,
                selectMembershipBonus: action.data
            }
        }

        case GET_SELECT_TRANSACTION_TAG: {
            return {
                ...state,
                selectTransactionTag: action.data
            }
        }

        case REMOVE_CLUB_LIST: {
            return {
                ...state,
                selectClub: action.data
            }
        }

        case REMOVE_EMPLOYEE_LIST: {
            return {
                ...state,
                selectEmployee: action.data
            }
        }

        default: {
            return {
                ...state,
            };
        }
    }
};

export default SelectReducer;
