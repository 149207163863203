import { Api } from '../api'

export const GET_LIST_TRANSACTION = "GET_LIST_TRANSACTION";
export const CLEAR_LIST_TRANSACTION = "CLEAR_LIST_TRANSACTION";
export const GET_DOWN_PAYMENT = "GET_DOWN_PAYMENT";

export const GET_TRANSACTION_MEMBER = "GET_TRANSACTION_MEMBER";
export const GET_BEST_REVENUE_SALES = "GET_BEST_REVENUE_SALES";

export const GET_CONDUCT_PT_FIRST_MONTH = 'GET_CONDUCT_PT_FIRST_MONTH';
export const GET_CONDUCT_PT_SECOND_MONTH = 'GET_CONDUCT_PT_SECOND_MONTH';
export const GET_CONDUCT_FTHPA_FIRST_MONTH = 'GET_CONDUCT_FTHPA_FIRST_MONTH';
export const GET_CONDUCT_FTHPA_SECOND_MONTH = 'GET_CONDUCT_FTHPA_SECOND_MONTH';

export const GET_ORLOG_TRANSACTION = 'GET_ORLOG_TRANSACTION';

export const GET_HISTORY_TRAINER_PT = "GET_HISTORY_TRAINER_PT";
export const GET_HISTORY_TRAINER_FT_RFT_HPA = "GET_HISTORY_TRAINER_FT_RFT_HPA";

export const GET_RATE_AUTOPAY = "GET_RATE_AUTOPAY";

export const GET_TRANSACTION_VOID = "GET_TRANSACTION_VOID";
export const GET_REPORT_RETAINER = "GET_REPORT_RETAINER";
export const GET_DETAIL_RETAINER = "GET_DETAIL_RETAINER";

export const GET_EMPLOYEE_GUEST_CHECKIN = "GET_EMPLOYEE_GUEST_CHECKIN";
export const GET_MEMBER_PT_CLIENT = "GET_MEMBER_PT_CLIENT";
export const GET_FC_REV_PERFORMANCE = "GET_FC_REV_PERFORMANCE";
export const GET_FC_UNIT_PERFORMANCE = "GET_FC_UNIT_PERFORMANCE";

export const GET_FC_PERFORMANCE_LIST = "GET_FC_PERFORMANCE_LIST";
export const GET_LIST_TRANSFER_PACKAGE = "GET_LIST_TRANSFER_PACKAGE";
export const GET_LIST_EXTEND_PT = "GET_LIST_EXTEND_PT";

export const CHECK_MEMBERSHIP_PROMO = "CHECK_MEMBERSHIP_PROMO";

export const getListTransaction = ({
	page = '', size = '', orgId = '', clubId = '', startDate = '', endDate = '',
	memberId = '', salesId = '', transactionType = '', search = '', isExport = false,
	packageMembershipId = '', packageTrainerId = ''
}) => {
	return async (dispatch) => {
		try {
			if (search === null) {
				search = "";
			}
			const resp = await Api.get(
				`transaction?page=${page}&size=${size}&orgId=${orgId}&clubId=${clubId}&startDate=${startDate}&endDate=${endDate}&memberId=${memberId}&salesId=${salesId}&transactionType=${transactionType}&search=${search}&packageMembershipId=${packageMembershipId}&packageTrainerId=${packageTrainerId}`
			)
			let data = resp.data.data ? resp.data.data : []

			if (isExport) {
				return data
			}

			dispatch({
				type: GET_LIST_TRANSACTION,
				data,
			})
		} catch (err) {
			console.log(err)
			throw err?.response?.data
		}
	}
};

export const clearListTransaction = () => {
	return async (dispatch) => {
		dispatch({
			type: CLEAR_LIST_TRANSACTION,
			data: []
		})
	}
}

export const getDownPayment = ({ page = "", size = "", orgId = "", clubId = "", salesId = "", orderNumber = "", searchMember = "", isExport = false }) => {
	return async (dispatch) => {
		try {
			if (orderNumber === null) orderNumber = "";
			if (searchMember === null) searchMember = "";

			const resp = await Api.get(
				`transaction-dp?page=${page}&size=${size}&orgId=${orgId}&clubId=${clubId}&salesId=${salesId}&orderNumber=${orderNumber}&searchMember=${searchMember}`
			)
			let data = resp.data.data ? resp.data.data : []

			if (isExport) {
				return data
			}

			dispatch({
				type: GET_DOWN_PAYMENT,
				data,
			})
		} catch (err) {
			console.log(err);
			throw err?.response?.data
		}
	}
}

export const getTransactionByMember = ({ page = '', size = '', orgId = '', clubId = '', memberId = '', search = '', withVoid = 1 }) => {
	return async (dispatch) => {
		if (search === null) search = '';
		try {
			const resp = await Api.get(
				`transaction-member?page=${page}&size=${size}&orgId=${orgId}&clubId=${clubId}&memberId=${memberId}&search=${search}&withVoid=${withVoid}`
			)
			let data = resp.data.data ? resp.data.data : [];

			dispatch({
				type: GET_TRANSACTION_MEMBER,
				data
			})

		} catch (err) {
			throw err?.response?.data;
		}
	}
}

export const getCountConduct = ({
	orgId = '', clubId = '', startDate = '', endDate = '', type = '', compare1 = false, compare2 = false
}) => {
	return async (dispatch) => {
		try {
			const resp = await Api.get(`report/count-conduct?orgId=${orgId}&clubId=${clubId}&startDate=${startDate}&endDate=${endDate}&type=${type}`)

			let data = resp.data.data ? resp.data.data : 0;
			let dispatchType = '';

			if (type === 'pt' && compare1) {
				dispatchType = GET_CONDUCT_PT_FIRST_MONTH
			} else if (type === 'pt' && compare2) {
				dispatchType = GET_CONDUCT_PT_SECOND_MONTH
			} else if (type === 'fthpa' && compare1) {
				dispatchType = GET_CONDUCT_FTHPA_FIRST_MONTH
			} else if (type === 'fthpa' && compare2) {
				dispatchType = GET_CONDUCT_FTHPA_SECOND_MONTH
			}

			dispatch({
				type: dispatchType,
				data,
			})
		} catch (err) {
			console.log(err)
			throw err?.response?.data
		}
	}
}

export const getBestRevenueSales = ({ orgId = '', clubId = '', startDate = '', endDate = '', month = '', year = '', isExport = false }) => {
	return async (dispatch) => {
		try {
			const resp = await Api.get(`report/best-revenue-sales?orgId=${orgId}&clubId=${clubId}&startDate=${startDate}&endDate=${endDate}&month=${month}&year=${year}`)

			let data = resp.data.data ? resp.data.data : []

			if (isExport) {
				return data;
			}

			dispatch({
				type: GET_BEST_REVENUE_SALES,
				data,
			})
			// return data
		} catch (err) {
			console.log(err)
			throw err?.response?.data
		}
	}
}

export const getOrLogTransaction = ({ page = '', size = '', orgId = '', clubId = '', startDate = '', endDate = '', transactionType = "", memberStatus = "", search = '', isExport = false }) => {
	return async (dispatch) => {
		try {
			if (search === null) search = '';
			const resp = await Api.get(
				`report/or-log/transaction?page=${page}&size=${size}&orgId=${orgId}&clubId=${clubId}&startDate=${startDate}&endDate=${endDate}&transactionType=${transactionType}&memberStatus=${memberStatus}&search=${search}`
			)

			let data = resp.data.data ? resp.data.data : null;

			if (isExport) {
				return data
			} else {
				dispatch({
					type: GET_ORLOG_TRANSACTION,
					data
				})
			}

		} catch (error) {
			console.log(error)
			return error?.response?.data
		}
	}
}

export const getHistoryTrainerSession = ({ page = '', size = '', orgId = '', clubId = '', memberId = '', startDate = '', endDate = '', trainerId = '', search = '', isExport = false }) => {
	return async (dispatch) => {
		try {
			if (search === null) search = "";
			const resp = await Api.get(
				`report/trainer-history?page=${page}&size=${size}&orgId=${orgId}&clubId=${clubId}&memberId=${memberId}&startDate=${startDate}&endDate=${endDate}&trainerId=${trainerId}&search=${search}`
			)

			let data = resp.data.data ? resp.data.data : null;

			if (isExport) {
				return data;
			}

			dispatch({
				type: GET_HISTORY_TRAINER_PT,
				data
			})
		} catch (error) {
			console.log(error);
			return error?.response?.data
		}
	}
}

export const getHistoryTrainerFtRftHpa = ({ page = '', size = '', orgId = '', clubId = '', startDate = '', endDate = '', trainerId = '', type = '', search = '', isExport = false }) => {
	return async (dispatch) => {
		try {
			if (search === null) search = '';
			const resp = await Api.get(
				`report/trainer/ft-rft-hpa?page=${page}&size=${size}&orgId=${orgId}&clubId=${clubId}&startDate=${startDate}&endDate=${endDate}&trainerId=${trainerId}&type=${type}&search=${search}`
			)

			let data = resp.data.data ? resp.data.data : null;

			if (isExport) {
				return data;
			}

			dispatch({
				type: GET_HISTORY_TRAINER_FT_RFT_HPA,
				data
			})

		} catch (error) {
			return error?.response?.data;
		}
	}
}

export const getRateAutopay = ({ orgId = "", clubId = "", month = "", year = "" }) => {
	return async (dispatch) => {
		try {
			const resp = await Api.get(
				`report/rate-autopay?orgId=${orgId}&clubId=${clubId}&month=${month}&year=${year}`
			)

			let data = resp.data.data ? resp.data.data : null;

			dispatch({
				type: GET_RATE_AUTOPAY,
				data
			})
		} catch (error) {
			console.log(error);
			return error?.response?.data
		}
	}
}

export const getTransactionVoid = ({ page = '', size = '', orgId = '', clubId = '', orderNumber = '', searchMember = '', isExport = false }) => {
	return async (dispatch) => {
		try {
			if (orderNumber === null) {
				orderNumber = "";
			}
			if (searchMember === null) {
				searchMember = "";
			}
			const resp = await Api.get(
				`transaction-void?page=${page}&size=${size}&orgId=${orgId}&clubId=${clubId}&orderNumber=${orderNumber}&searchMember=${searchMember}`
			)

			let data = resp.data.data ? resp.data.data : null;

			if (isExport) {
				return data;
			}

			dispatch({
				type: GET_TRANSACTION_VOID,
				data
			})

		} catch (error) {
			console.log(error);
			return error?.response?.data
		}
	}
}

export const getReportRetainer = ({ page = '', size = '', orgId = '', clubId = '', month = '', year = '', salesId = '', isExport = false }) => {
	return async (dispatch) => {
		try {
			const resp = await Api.get(
				`report/retainer?page=${page}&size=${size}&orgId=${orgId}&clubId=${clubId}&month=${month}&year=${year}&salesId=${salesId}`
			)

			let data = resp.data.data ? resp.data.data : null;

			if (isExport) {
				return data;
			}

			dispatch({
				type: GET_REPORT_RETAINER,
				data
			})

		} catch (error) {
			console.log(error);
			return error?.response?.data
		}
	}
}

export const getRetainerDetail = ({ page = '', size = '', orgId = '', clubId = '', salesId = '', month = '', year = '', search = '', isExport = false }) => {
	return async (dispatch) => {
		try {
			if (search === null) search = ""

			const resp = await Api.get(
				`report/retainer/detail?page=${page}&size=${size}&orgId=${orgId}&clubId=${clubId}&salesId=${salesId}&month=${month}&year=${year}&search=${search}`
			)

			let data = resp.data.data ? resp.data.data : null;

			if (isExport) {
				return data
			}

			dispatch({
				type: GET_DETAIL_RETAINER,
				data
			})
		} catch (error) {
			console.log(error);
			return error?.response?.data
		}
	}
}

export const getEmployeeGuestCheckin = ({ page = '', size = '', orgId = '', clubId = '', employeeId = '', startDate = '', endDate = '', isExport = false }) => {
	return async (dispatch) => {
		try {
			const resp = await Api.get(
				`report/employee-guest-checkin?page=${page}&size=${size}&orgId=${orgId}&clubId=${clubId}&employeeId=${employeeId}&startDate=${startDate}&endDate=${endDate}`
			)

			let data = resp.data.data ? resp.data.data : null;

			if (isExport) {
				return data;
			}

			dispatch({
				type: GET_EMPLOYEE_GUEST_CHECKIN,
				data
			})
		} catch (error) {
			console.log(error);
			return error?.response?.data
		}
	}
}

export const getMemberPtClient = ({ page = '', size = '', orgId = '', clubId = '', search = '', isExport = false }) => {
	return async (dispatch) => {
		try {
			if (search == null) search = "";
			const resp = await Api.get(
				`report/employee-pt-client?page=${page}&size=${size}&orgId=${orgId}&clubId=${clubId}&search=${search}`
			)

			let data = resp.data.data ? resp.data.data : null;

			if (isExport) {
				return data;
			}

			dispatch({
				type: GET_MEMBER_PT_CLIENT,
				data
			})
		} catch (error) {
			return error?.response?.data;
		}
	}
}

export const getFcRevPerformance = ({
	orgId = '', clubId = '', startDate = '', startMonth = '', endDate = '', fullMonth = '', fullYear = '', lastDateMonth = ''
}) => {
	return async (dispatch) => {
		try {
			const resp = await Api.get(
				`report/fc-rev-performance?orgId=${orgId}&clubId=${clubId}&startDate=${startDate}&startMonth=${startMonth}&endDate=${endDate}&fullMonth=${fullMonth}&fullYear=${fullYear}&lastDateMonth=${lastDateMonth}`
			)

			let data = resp.data.data ? resp.data.data : null;

			dispatch({
				type: GET_FC_REV_PERFORMANCE,
				data
			})
		} catch (error) {
			console.log(error);
			return error?.response?.data
		}
	}
}

export const getFcUnitPerformance = ({
	orgId = '', clubId = '', startDate = '', startMonth = '', endDate = '', fullMonth = '', fullYear = '', lastDateMonth = ''
}) => {
	return async (dispatch) => {
		try {
			const resp = await Api.get(
				`report/fc-unit-performance?orgId=${orgId}&clubId=${clubId}&startDate=${startDate}&startMonth=${startMonth}&endDate=${endDate}&fullMonth=${fullMonth}&fullYear=${fullYear}&lastDateMonth=${lastDateMonth}`
			)

			let data = resp.data.data ? resp.data.data : null;

			dispatch({
				type: GET_FC_UNIT_PERFORMANCE,
				data
			})
		} catch (error) {
			console.log(error);
			return error?.response?.data
		}
	}
}

export const getFcPerformance = ({
	orgId = '', clubId = '', startFirstMonth = '', endFirstMonth = '', startSecondMonth = '', endSecondMonth
}) => {
	return async (dispatch) => {
		try {
			const resp = await Api.get(
				`report/fc-performance-list?orgId=${orgId}&clubId=${clubId}&startFirstMonth=${startFirstMonth}&endFirstMonth=${endFirstMonth}&startSecondMonth=${startSecondMonth}&endSecondMonth=${endSecondMonth}`
			)

			let data = resp.data.data ? resp.data.data : null;

			dispatch({
				type: GET_FC_PERFORMANCE_LIST,
				data
			})
		} catch (error) {
			console.log(error);
			return error?.response?.data
		}
	}
}

export const getListTransfer = ({
	page = '', size = '', orgId = '', clubId = '', status = '', search = '', isExport = false
}) => {
	return async (dispatch) => {
		try {
			const resp = await Api.get(
				`member-transfer?page=${page}&size=${size}&orgId=${orgId}&clubId=${clubId}&status=${status}&search=${search}`
			)

			let data = resp.data.data ? resp.data.data : null;

			if (isExport) {
				return data
			}

			dispatch({
				type: GET_LIST_TRANSFER_PACKAGE,
				data
			})
		} catch (error) {
			console.log(error);
			return error?.response?.data
		}
	}
}

export const getExtendPt = ({
	page = '', size = '', orgId = '', clubId = '', status = '', search = '', isExport = false
}) => {
	return async (dispatch) => {
		try {
			const resp = await Api.get(
				`list-extend-pt?page=${page}&size=${size}&orgId=${orgId}&clubId=${clubId}&status=${status}&search=${search}`
			)

			let data = resp.data.data ? resp.data.data : null;

			if (isExport) {
				return data;
			}

			dispatch({
				type: GET_LIST_EXTEND_PT,
				data
			})
		} catch (error) {
			console.log(error);
			return error?.response?.data
		}
	}
}

export const checkMembershipPromo = (payload) => {
	return async (dispatch) => {
		try {
			const resp = await Api.post(`check-promo`, payload)

			let data = resp.data.data ? resp.data.data : null;

			dispatch({
				type: CHECK_MEMBERSHIP_PROMO,
				data
			})
		} catch (error) {
			console.log(error);
			return error?.response?.data;
		}
	}
}
