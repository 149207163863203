import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const appIndex = lazy(() => import("./AppIndex"));

const guestFormAccessRoutes = [
  {
    path: "/system/guestForm/access",
    component: appIndex,
    auth: authRoles.admin
  }
];

export default guestFormAccessRoutes;
