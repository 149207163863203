import {
    GET_LIST_MEMBER,
    GET_ONLY_MEMBER,
    GET_DETAIL_MEMBER,
    GET_LIST_CUTI,
    GET_DETAIL_CUTI,
    GET_REPORT_MEMBER_CHECKIN,
    GET_MEMBER_EFT_INVOICE,

    GET_MEMBER_CHECKIN_TODAY_PEAK_HOURS,
    GET_MEMBER_CHECKIN_MTD_PEAK_HOURS,
    GET_MEMBER_CHECKIN_TODAY_OFF_PEAK_HOURS,
    GET_MEMBER_CHECKIN_MTD_OFF_PEAK_HOURS,

    GET_MEMBER_PIF_EXPIRED,

    GET_COMPARE_MEMBER_CHECKIN_TODAY_PEAK,
    GET_COMPARE_MEMBER_CHECKIN_TODAY_OFF_PEAK,

    GET_COMPARE_MEMBER_CHECKIN_FIRST_MTD_PEAK,
    GET_COMPARE_MEMBER_CHECKIN_FIRST_MTD_OFF_PEAK,
    GET_COMPARE_MEMBER_CHECKIN_SECOND_MTD_PEAK,
    GET_COMPARE_MEMBER_CHECKIN_SECOND_MTD_OFF_PEAK,

    GET_MEMBER_FREE_TRIAL,
    GET_MEMBER_CHECKIN_CHECKOUT,
    GET_MEMBER_ACTIVE,
    GET_MEMBER_REFFERAL,
    GET_MEMBER_NOTES,
    GET_MEMBER_99K,
    GET_HISTORY_COIN,

    CLEAR_LIST_MEMBER,
} from "../actions/MemberActions";

const initialState = {
    memberList: [],
    memberOnly: [],
    memberDetail: [],
    listCuti: [],
    detailCuti: [],
    memberCheckin: [],
    eftInvoice: [],
    countMemberCheckin: {
        peakHours: {
            today: "",
            month: "",
        },
        offPeakHours: {
            today: "",
            month: "",
        },
    },
    compareCountMemberCheckin: {
        peakHours: {
            today: "",
            firstMonth: "",
            secondMonth: "",
        },
        offPeakHours: {
            today: "",
            firstMonth: "",
            secondMonth: "",
        },
    },
    memberPIFExpired: [],
    memberFreeTrial: [],
    memberCheckinCheckout: [],
    memberActive: [],
    memberRefferal: [],
    memberNotes: [],
    member99k: [],
    historyCoin: [],
};

const MemberReducer = function(state = initialState, action) {
    switch (action.type) {
        case GET_LIST_MEMBER: {
            return {
                ...state,
                memberList: action.data
            };
        }

        case GET_ONLY_MEMBER: {
            return {
                ...state,
                memberOnly: action.data
            }
        }

        case GET_DETAIL_MEMBER: {
            return {
                ...state,
                memberDetail: action.data
            }
        }

        case GET_LIST_CUTI: {
            return {
                ...state,
                listCuti: action.data
            };
        }

        case GET_DETAIL_CUTI: {
            return {
                ...state,
                detailCuti: action.data
            };
        }

        case GET_REPORT_MEMBER_CHECKIN: {
            return {
                ...state,
                memberCheckin: action.data
            }
        }

        case GET_MEMBER_EFT_INVOICE: {
            return {
                ...state,
                eftInvoice: action.data
            }
        }

        case GET_MEMBER_CHECKIN_TODAY_PEAK_HOURS: {
            return {
                ...state,
                countMemberCheckin: {
                    ...state.countMemberCheckin,
                    peakHours: {
                        ...state.countMemberCheckin.peakHours,
                        today: action.data
                    }
                }
            }
        }
        case GET_MEMBER_CHECKIN_TODAY_OFF_PEAK_HOURS: {
            return {
                ...state,
                countMemberCheckin: {
                    ...state.countMemberCheckin,
                    offPeakHours: {
                        ...state.countMemberCheckin.offPeakHours,
                        today: action.data
                    }
                }
            }
        }
        case GET_MEMBER_CHECKIN_MTD_PEAK_HOURS: {
            return {
                ...state,
                countMemberCheckin: {
                    ...state.countMemberCheckin,
                    peakHours: {
                        ...state.countMemberCheckin.peakHours,
                        month: action.data
                    }
                }
            }
        }
        case GET_MEMBER_CHECKIN_MTD_OFF_PEAK_HOURS: {
            return {
                ...state,
                countMemberCheckin: {
                    ...state.countMemberCheckin,
                    offPeakHours: {
                        ...state.countMemberCheckin.offPeakHours,
                        month: action.data
                    }
                }
            }
        }

        case GET_COMPARE_MEMBER_CHECKIN_TODAY_OFF_PEAK: {
            return {
                ...state,
                compareCountMemberCheckin: {
                    ...state.compareCountMemberCheckin,
                    offPeakHours: {
                        ...state.compareCountMemberCheckin.offPeakHours,
                        today: action.data
                    }
                }
            }
        }

        case GET_COMPARE_MEMBER_CHECKIN_TODAY_PEAK: {
            return {
                ...state,
                compareCountMemberCheckin: {
                    ...state.compareCountMemberCheckin,
                    peakHours: {
                        ...state.compareCountMemberCheckin.peakHours,
                        today: action.data
                    }
                }
            }
        }

        case GET_COMPARE_MEMBER_CHECKIN_FIRST_MTD_PEAK: {
            return {
                ...state,
                compareCountMemberCheckin: {
                    ...state.compareCountMemberCheckin,
                    peakHours: {
                        ...state.compareCountMemberCheckin.peakHours,
                        firstMonth: action.data
                    }
                }
            }
        }

        case GET_COMPARE_MEMBER_CHECKIN_FIRST_MTD_OFF_PEAK: {
            return {
                ...state,
                compareCountMemberCheckin: {
                    ...state.compareCountMemberCheckin,
                    offPeakHours: {
                        ...state.compareCountMemberCheckin.offPeakHours,
                        firstMonth: action.data
                    }
                }
            }
        }

        case GET_COMPARE_MEMBER_CHECKIN_SECOND_MTD_PEAK: {
            return {
                ...state,
                compareCountMemberCheckin: {
                    ...state.compareCountMemberCheckin,
                    peakHours: {
                        ...state.compareCountMemberCheckin.peakHours,
                        secondMonth: action.data
                    }
                }
            }
        }

        case GET_COMPARE_MEMBER_CHECKIN_SECOND_MTD_OFF_PEAK: {
            return {
                ...state,
                compareCountMemberCheckin: {
                    ...state.compareCountMemberCheckin,
                    offPeakHours: {
                        ...state.compareCountMemberCheckin.offPeakHours,
                        secondMonth: action.data
                    }
                }
            }
        }

        case GET_MEMBER_PIF_EXPIRED: {
            return {
                ...state,
                memberPIFExpired: action.data
            }
        }

        case GET_MEMBER_FREE_TRIAL: {
            return {
                ...state,
                memberFreeTrial: action.data
            }
        }

        case GET_MEMBER_CHECKIN_CHECKOUT: {
            return {
                ...state,
                memberCheckinCheckout: action.data
            }
        }

        case GET_MEMBER_ACTIVE: {
            return {
                ...state,
                memberActive: action.data
            }
        }

        case GET_MEMBER_REFFERAL: {
            return {
                ...state,
                memberRefferal: action.data
            }
        }

        case GET_MEMBER_NOTES: {
            return {
                ...state,
                memberNotes: action.data
            }
        }

        case GET_MEMBER_99K: {
            return {
                ...state,
                member99k: action.data
            }
        }

        case GET_HISTORY_COIN: {
            return {
                ...state,
                historyCoin: action.data
            }
        }

        case CLEAR_LIST_MEMBER: {
            return {
                ...state,
                memberList: action.data
            }
        }
        default: {
            return {
                ...state,
            };
        }
    }
};

export default MemberReducer;
