import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const appIndex = lazy(() => import("./AppIndex"));

const listDpRoutes = [
  {
    path: "/transaction/pay-down-payment",
    component: appIndex,
    auth: authRoles.admin
  }
];

export default listDpRoutes;
