import {
    GET_LIST_MEMBERSHIP,
    
    GET_COMPARE_PIF_TODAY,
    GET_COMPARE_EFT_TODAY,
    GET_COMPARE_PIF_MTD,
    GET_COMPARE_EFT_MTD,
    GET_COMPARE_PIF_FIRST_MONTH,
    GET_COMPARE_EFT_FIRST_MONTH,
    GET_COMPARE_PIF_SECOND_MONTH,
    GET_COMPARE_EFT_SECOND_MONTH,

    GET_TOTAL_EFT_MTD,
    GET_TOTAL_EFT_TODAY,
    GET_TOTAL_PIF_MTD,
    GET_TOTAL_PIF_TODAY,
} from "../actions/MembershipActions";

const initialState = {
  membershipList: [],
  totalUnitPif: { today: "", mtd: "" },
  totalUnitEft: { today: "", mtd: "" },

  compareUnit: {
    pif: {
      today: "",
      mtd: "",
      firstMonth: "",
      secondMonth: "",
    },
    eft: {
      today: "",
      mtd: "",
      firstMonth: "",
      secondMonth: ""
    }
  }
};

const MembershipReducer = function(state = initialState, action) {
  switch (action.type) {
    case GET_LIST_MEMBERSHIP: {
      return {
        ...state,
        membershipList: action.data
      };
    }
    case GET_TOTAL_PIF_TODAY: {
      return {
        ...state,
        totalUnitPif: {
          ...state.totalUnitPif,
          today: action.data.data
        }
      }
    }
    case GET_TOTAL_PIF_MTD: {
      return {
        ...state,
        totalUnitPif: {
          ...state.totalUnitPif,
          mtd: action.data.data
        }
      }
    }
    case GET_TOTAL_EFT_TODAY: {
      return {
        ...state,
        totalUnitEft: {
          ...state.totalUnitEft,
          today: action.data.data
        }
      }
    }
    case GET_TOTAL_EFT_MTD: {
      return {
        ...state,
        totalUnitEft: {
          ...state.totalUnitEft,
          mtd: action.data.data
        }
      }
    }
    case GET_COMPARE_PIF_TODAY: {
      return {
        ...state,
        compareUnit: {
          ...state.compareUnit,
          pif: {
            ...state.compareUnit.pif,
            today: action.data
          }
        }
      }
    }
    case GET_COMPARE_EFT_TODAY: {
      return {
        ...state,
        compareUnit: {
          ...state.compareUnit,
          eft: {
            ...state.compareUnit.eft,
            today: action.data
          }
        }
      }
    }
    case GET_COMPARE_PIF_MTD: {
      return {
        ...state,
        compareUnit: {
          ...state.compareUnit,
          pif: {
            ...state.compareUnit.pif,
            mtd: action.data
          }
        }
      }
    }
    case GET_COMPARE_EFT_MTD: {
      return {
        ...state,
        compareUnit: {
          ...state.compareUnit,
          eft: {
            ...state.compareUnit.eft,
            mtd: action.data
          }
        }
      }
    }
    case GET_COMPARE_PIF_FIRST_MONTH: {
      return {
        ...state,
        compareUnit: {
          ...state.compareUnit,
          pif: {
            ...state.compareUnit.pif,
            firstMonth: action.data
          }
        }
      }
    }
    case GET_COMPARE_EFT_FIRST_MONTH: {
      return {
        ...state,
        compareUnit: {
          ...state.compareUnit,
          eft: {
            ...state.compareUnit.eft,
            firstMonth: action.data
          }
        }
      }
    }
    case GET_COMPARE_PIF_SECOND_MONTH: {
      return {
        ...state,
        compareUnit: {
          ...state.compareUnit,
          pif: {
            ...state.compareUnit.pif,
            secondMonth: action.data
          }
        }
      }
    }
    case GET_COMPARE_EFT_SECOND_MONTH: {
      return {
        ...state,
        compareUnit: {
          ...state.compareUnit,
          eft: {
            ...state.compareUnit.eft,
            secondMonth: action.data
          }
        }
      }
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default MembershipReducer;
