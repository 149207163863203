import { Api } from '../api'

export const GET_LIST_SOURCES  = "GET_LIST_SOURCES";
export const GET_LIST_SUB_SOURCES = "GET_LIST_SUB_SOURCES";

export const getListSource = ({ page = "", size = "", search = "", isExport = false }) => {
    return async (dispatch) => {
        try {
            if (!search) search = "";
            const resp = await Api.get(
                `master/source?page=${page}&size=${size}&search=${search}`
            )
            let data = resp.data.data ? resp.data.data : []

            if (isExport) return data;

            dispatch({
                type: GET_LIST_SOURCES,
                data,
            })
            // return data
        } catch (err) {
            console.log(err)
            throw err?.response?.data
        }
    }
};

export const getListSubSource = ({ page = '', size = '', sourceId = '', search = "", isExport = false }) => {
    return async (dispatch) => {
        try {
            if (!search) search = "";
            const resp = await Api.get(
                `master/sub-source?page=${page}&size=${size}&sourceId=${sourceId}&search=${search}`
            )
            let data = resp.data.data ? resp.data.data : []

            if (isExport) return data;

            dispatch({
                type: GET_LIST_SUB_SOURCES,
                data,
            })
            // return data
        } catch (err) {
            console.log(err)
            throw err?.response?.data
        }
    }
};