import { Api } from '../api'

export const GET_LIST_IMAGE = "GET_LIST_IMAGE";

export const getListImage = (page, size, orgId) => {
  return async (dispatch) => {
    try {
      const resp = await Api.get(`image-slider?page=${page}&size=${size}&orgId=${orgId}`)
      let data = resp.data.data ? resp.data.data : []

      dispatch({
        type: GET_LIST_IMAGE,
        data,
      })
      // return data
    } catch (err) {
      console.log(err)
      throw err?.response?.data
    }
  }
};

