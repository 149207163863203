import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const appIndex = lazy(() => import("./AppIndex"));

const packageClassRoutes = [
  {
    path: "/package/class",
    component: appIndex,
    auth: authRoles.admin
  }
];

export default packageClassRoutes;
