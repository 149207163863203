import { 
    GET_CLASSES, 
    GET_CLASS_CATEGORY, 
    GET_INSTRUCTOR, 
    GET_PACKAGE_CLASS, 
    GET_CLASS_MOVEMENT,
    GET_MOVEMENT_DETAIL, 
} from "../actions/ClassActions";

const initialState = {
    classes: [],
    classCategory: [],
    classMovement: [],
    movementDetail: [],
    instructor: [],
    packageClass: [],
};

const ClassReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_CLASSES: {
            return {
                ...state,
                classes: action.data,
            };
        }

        case GET_CLASS_CATEGORY: {
            return {
                ...state,
                classCategory: action.data,
            };
        }

        case GET_INSTRUCTOR: {
            return {
                ...state,
                instructor: action.data,
            };
        }

        case GET_PACKAGE_CLASS: {
            return {
                ...state,
                packageClass: action.data,
            };
        }

        case GET_CLASS_MOVEMENT: {
            return {
                ...state,
                classMovement: action.data,
            }
        }

        case GET_MOVEMENT_DETAIL: {
            return {
                ...state,
                movementDetail: action.data,
            }
        }

        default: {
            return {
                ...state,
            };
        }
    }
};

export default ClassReducer;