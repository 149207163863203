import { Api } from '../api'

export const GET_LIST_MEMBERSHIP = "GET_LIST_MEMBERSHIP";

export const getListMembership = ({
  page, orgId = "", clubId = "", paymentId = "", categoryId = "", promoId = "", shiftId = "", size, search = "", isMobile = "", isFreeTrial = ""
}) => {
  return async (dispatch) => {
    try {
      if (search === null) search = "";
      const resp = await Api.get(
        `package/membership?page=${page}&orgId=${orgId}&clubId=${clubId}&paymentId=${paymentId}&categoryId=${categoryId}&promoId=${promoId}&shiftId=${shiftId}&size=${size}&search=${search}&isMobile=${isMobile}&isFreeTrial=${isFreeTrial}`
      )
      let data = resp.data.data ? resp.data.data : []

      dispatch({
        type: GET_LIST_MEMBERSHIP,
        data,
      })
      // return data
    } catch (err) {
      console.log(err)
      throw err?.response?.data
    }
  }
};