import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const appIndex = lazy(() => import("./AppIndex"));

const classRoutes = [
  {
    path: "/master/classes/class",
    component: appIndex,
    auth: authRoles.admin
  }
];

export default classRoutes;
