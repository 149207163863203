import { GET_BOOKING_CLASS, GET_BOOKING_TRAINER } from "../actions/BookingActions";

const initialState = {
  bookingClass: [],
  bookingTrainer: [],
};

const BookingReducer = function(state = initialState, action) {
  switch (action.type) {
    case GET_BOOKING_CLASS: {
      return {
        ...state,
        bookingClass: action.data
      };
    }

    case GET_BOOKING_TRAINER: {
      return {
        ...state,
        bookingTrainer: action.data
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};

export default BookingReducer;
