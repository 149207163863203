import { Api } from '../api'

export const GET_LIST_KPI = "GET_LIST_KPI";

export const getListKpi = (page, size, departmentId = '') => {
  return async (dispatch) => {
    try {
      const resp = await Api.get(`kpi?page=${page}&size=${size}&departmentId=${departmentId}`)
      let data = resp.data.data ? resp.data.data : []

      dispatch({
        type: GET_LIST_KPI,
        data,
      })
      // return data
    } catch (err) {
      console.log(err)
      throw err?.response?.data
    }
  }
};