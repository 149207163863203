import { GET_LIST_LEVEL, GET_PERSONAL_TRAINER, GET_PACKAGE_TRAINER } from "../actions/TrainerActions";

const initialState = {
  trainerLevel: [],
  personalTrainer: [],
};

const TrainerReducer = function(state = initialState, action) {
  switch (action.type) {
    case GET_LIST_LEVEL: {
      return {
        ...state,
        trainerLevel: action.data
      };
    }
    case GET_PERSONAL_TRAINER: {
      return {
        ...state,
        personalTrainer: action.data
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default TrainerReducer;
