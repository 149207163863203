import { Api } from '../api'

export const GET_LIST_ORG = "GET_LIST_ORG";

export const getListBrand = (page, size) => {
  return async (dispatch) => {
    try {
      const resp = await Api.get(`org?page=${page}&size=${size}`)
      let data = resp.data.data ? resp.data.data : []

      dispatch({
        type: GET_LIST_ORG,
        data,
      })
      // return data
    } catch (err) {
      console.log(err)
      throw err?.response?.data
    }
  }
};

// export const deleteNotification = id => dispatch => {
//   axios.post("/api/notification/delete", { id }).then(res => {
//     dispatch({
//       type: DELETE_NOTIFICATION,
//       payload: res.data
//     });
//   });
// };

// export const deleteAllNotification = () => dispatch => {
//   axios.post("/api/notification/delete-all").then(res => {
//     dispatch({
//       type: DELETE_ALL_NOTIFICATION,
//       payload: res.data
//     });
//   });
// };

// export const createNotification = notification => dispatch => {
//   axios.post("/api/notification/add", { notification }).then(res => {
//     dispatch({
//       type: CREATE_NOTIFICATION,
//       payload: res.data
//     });
//   });
// };
