import { Api } from '../api'

export const GET_GUEST_FORM = 'GET_GUEST_FORM';
export const GET_GUEST_FORM_ACCESS = 'GET_GUEST_FORM_ACCESS';
export const GET_LIST_ACCESS = 'GET_LIST_ACCESS';
export const CLEAR_GUEST_FORM = 'CLEAR_GUEST_FORM';
export const CLEAR_ACCESS = 'CLEAR_ACCESS';

export const getGuestForm = ({ memberId, leadId }) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`guest-form?memberId=${memberId}&leadId=${leadId}`)

            let data = resp.data.data ? resp.data.data : null;

            dispatch({
                type: GET_GUEST_FORM,
                data
            })

        } catch (error) {
            console.log(error)
            return error?.response?.data
        }
    }
}

export const getAccess = ({ orgId, clubId }) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`guest-form/access?orgId=${orgId}&clubId=${clubId}`)

            let data = resp.data.data ? resp.data.data : null;

            dispatch({
                type: GET_GUEST_FORM_ACCESS,
                data
            })
        } catch (error) {
            console.log(error);
            return error?.response?.data;
        }
    }
}

export const getListAccess = ({ orgId = "", clubId = "" }) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`guest-form/access/list?orgId=${orgId}&clubId=${clubId}`)

            let data = resp.data.data ? resp.data.data : null;

            dispatch({
                type: GET_LIST_ACCESS,
                data
            })
        } catch (error) {
            console.log(error);
            return error?.response?.data;
        }
    }
}

export const clearGuestForm = () => {
    return async (dispatch) => {
        try {
            dispatch({
                type: CLEAR_GUEST_FORM,
            })
        } catch (error) {
            return error?.response?.data
        }
    }
}

export const clearAccess = () => {
    return async (dispatch) => {
        try {
            dispatch({
                type: CLEAR_ACCESS
            })
        } catch (error) {
            return error?.response?.data
        }
    }
}
