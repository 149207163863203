import { GET_LIST_IMAGE } from "../actions/ImagesliderActions";

const initialState = {
    listImage: [],
};

const ImagesliderReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_LIST_IMAGE: {
            return {
                ...state,
                listImage: action.data,
            };
        }

        default: {
            return {
                ...state,
            };
        }
    }
};

export default ImagesliderReducer;