import { GET_LIST_COMPLEMENTARY } from "../actions/ComplementaryActions";

const initialState = {
  complementaryList: [],
};

const ComplementaryReducer = function(state = initialState, action) {
  switch (action.type) {
    case GET_LIST_COMPLEMENTARY: {
      return {
        ...state,
        complementaryList: action.data
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default ComplementaryReducer;
