import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const appIndex = lazy(() => import("./AppIndex"));

const membershipRoutes = [
  {
    path: "/package/membership",
    component: appIndex,
    auth: authRoles.admin
  },
];

export default membershipRoutes;
