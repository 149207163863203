import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const appIndex = lazy(() => import("./AppIndex"));

const classTypeRoutes = [
    {
        path: "/master/classes/type",
        component: appIndex,
        auth: authRoles.admin
    }
];

export default classTypeRoutes;
